.dashboard-grid-container {
  column-count: 3;
  column-gap: 10px;
  padding: 1rem;
}
.dashboard-grid-container-1col {
  column-count: 1;
  column-gap: 10px;
  padding: 1rem;
}

.dashboard-grid-container-4col {
  column-count: 4;
  column-gap: 10px;
  padding: 1rem;
}
.dashboard-item {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
}

@media (max-width: 1400px) {
  .dashboard-grid-container {
    column-count: 2;
  }
}

@media (max-width: 900px) {
  .dashboard-grid-container {
    column-count: 1;
  }
}

.compliance-grid-container {
  column-count: 3;
  column-gap: 10px;
}

@media (max-width: 2100px) {
  .compliance-grid-container {
    column-count: 2;
  }
}

@media (max-width: 1100px) {
  .compliance-grid-container {
    column-count: 1;
  }
}
