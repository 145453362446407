.MuiTablePagination-selectLabel {
  margin-bottom: 0px;
}

.MuiTablePagination-displayedRows {
  margin-bottom: 0px;
}

.MuiDataGrid-panel {
  width: 950px;
}

.MuiDataGrid-filterForm {
  width: 950px;
}
.no-border {
  border: 0;
}
.MuiDataGrid-filterFormOperatorInput {
  width: 170px !important;
}

.hidden-date-input {
  visibility: hidden !important;
  width: 0;
}

.date-filter-display {
  width: 80px;
  font-size: 15px;
}

.mobile-pagination {
  position: fixed;
  top: 100px;
  height: 42px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ced4da;
}
.mobile-card-container {
  margin-top: 90px;
  overflow-y: auto !important;
  height: calc(100vh - 150px);
}
.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}
