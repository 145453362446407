.settings-root {
    flex-grow: 1;

    justify-content: center;
    align-items: center;
}

.settings-paper {
    height: 140px;
    width: 140px;
    text-align: center;
    align-items: center;
    padding: 5px;
    color: #5B5B5B !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
}

.settings-paper:hover {
    background: #F7F7F7 !important;
}

.settings-div {

    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}