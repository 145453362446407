.avatar-div {
  top: 0px !important;
  display: flex;
  align-items: center;
}

.policy-container {
  padding: 20px;
}
.notification-list-item:hover {
  background: #f7f7f7 !important;
}
.notification-list-item {
  cursor: pointer;
}
