@media print {
  .lynx-nav {
    display: none;
  }
  .content-wrapper {
    display: block !important;
    height: fit-content !important;
    overflow: visible !important;
    position: relative !important;
  }
  .row {
    display: block !important;
    height: fit-content !important;
    overflow: visible !important;
    position: relative !important;
  }
  .form-container {
    display: block !important;
    height: fit-content !important;
    overflow: visible !important;
    position: relative !important;
  }

  .col {
    display: block !important;
    height: fit-content !important;
    overflow: visible !important;
    position: relative !important;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}
@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
@media all {
  .page-break {
    display: none;
  }
}
